<template>
  <component :is="rootTag">
    <v-form ref="form" v-model="valid">
      <validations-alert :type="formAlertType" :errors="formAlertErrorList" :message="formAlertMessage" />
      <slot v-if="!formId" />
      <div class="d-flex flex-row justify-center align-center">
        <v-progress-circular v-if="loadingForm" indeterminate />
      </div>
      <v-schema-renderer
        v-if="form && !loading"
        v-model="formValues"
        :children="formBodyChildren"
        :components-dictionary="widgetsDictionary"
        :rules-dictionary="rulesDictionary"
        :pre-processor="preProcessWidget"
      />
    </v-form>
    <slot v-if="$slots.action" name="action" />
    <v-divider v-if="showDivider" />
    <v-card-actions v-else>
      <v-btn
        :loading="loading"
        :color="submitBtnColor"
        @click="submitForm"
      >{{ submitBtnLabel }}</v-btn>
    </v-card-actions>
  </component>
</template>

<script>
  import ValidationsAlert from '@peynman/press-vue-admin/components/Inputs/ValidationsAlert/ValidationsAlert.vue'
  import FormValidations from '@peynman/press-vue-core/mixins/FormValidations'
  import User from '@peynman/press-vue-core/mixins/User'
  import WidgetsRenderer from '@peynman/press-vue-core/mixins/WidgetsRenderer'

  export default {
    name: 'FormSubmit',
    components: {
      ValidationsAlert,
    },
    mixins: [
      WidgetsRenderer,
      User,
      FormValidations(),
    ],
    props: {
      tag: String,
      url: String,
      method: String,
      onlyUsers: Boolean,
      submitLabel: String,
      showDivider: Boolean,
      successMessage: String,
      formId: [Number, String],
    },
    data: vm => ({
      valid: true,
      loading: false,
      loadingForm: false,
      form: null,
      formValues: {},
    }),
    computed: {
      submitBtnLabel () {
        return this.submitLabel || this.$t('components.website.formSubmit.submit')
      },
      submitBtnColor () {
        return this.formAlertType
      },
      rootTag () {
        return this.tag || 'div'
      },
      formBodyChildren () {
        return this.form?.data?.content?.children
      },
    },
    watch: {
      formId () {
        if (this.form?.id !== parseInt(this.formId)) {
          this.loadForm()
        }
      },
    },
    mounted () {
      if (!this.form && this.formId) {
        this.loadForm()
      }
    },
    methods: {
      loadForm () {
        this.loadingForm = true
        this.form = null
        this.$store.dispatch('repos/fetchForm', this.formId)
          .then(form => {
            this.form = form
          })
          .catch(error => {
            this.updateFormValidationErrors(error)
          })
          .finally(() => {
            this.loadingForm = false
          })
      },
      submitForm () {
        this.$refs.form.validate()

        if (this.valid) {
          this.resetFormValidations()

          this.loading = true
          if (this.onlyUsers) {
            if (this.isLoggedIn) {
              this.forceSendFormData()
                .then(json => {
                  if (this.successMessage) {
                    this.updateFormSuccessMessage(this.successMessage)
                  } else {
                    if (this.form?.data?.successMessage) {
                      this.updateFormSuccessMessage(this.form?.data?.successMessage)
                    } else {
                      this.updateFormSuccessMessage(json.message)
                    }
                  }
                })
                .catch(error => {
                  this.updateFormValidationErrors(error)
                })
                .finally(() => {
                  this.loading = false
                })
            } else {
              this.$store.commit('openSigninDialog')
            }
          } else {
            this.forceSendFormData()
              .then(json => {
                if (this.successMessage) {
                  this.updateFormSuccessMessage(this.successMessage)
                } else {
                  if (this.form?.data?.successMessage) {
                    this.updateFormSuccessMessage(this.form?.data?.successMessage)
                  } else {
                    this.updateFormSuccessMessage(json.message)
                  }
                }
              })
              .catch(error => {
                this.updateFormValidationErrors(error)
              })
              .finally(() => {
                this.loading = false
              })
          }
        }
      },
      forceSendFormData () {
        if (this.formId) {
          const method = this.onlyUsers ? 'submitSignedForm' : 'submitUnsignedForm'
          return this.$store.dispatch(`forms/${method}`, {
            formId: this.formId,
            data: {},
          })
        }
      },
    },
  }
</script>
